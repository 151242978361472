/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

import { Box, ResponsiveContext, TextArea, TextInput } from "grommet"
import { mainBlue } from "../styles/constants"
import GrommetWrapper from "../components/_shared_/grommet"
import SEO from "../components/_shared_/seo"
import SlovaccLogo from "../images/slovacc.svg"
import Cookies from "../components/_shared_/cookies"

const Slovacc = loadable(() => pMinDelay(import(`../components/pages/slovacc`), 800), {
  fallback: (
    <Box background={mainBlue} width="100vw" height="100vh" justify="center" align="center">
      <SlovaccLogo width="120px" className="pulse" />
    </Box>
  ),
})

const IndexPage: React.FunctionComponent = () => {
  useEffect(() => window.scrollTo(0, 0), [])
  return (
    <GrommetWrapper>
      <SEO
        title="SlovAcc: Účtovníctvo pre každého | vyskúšajte 1. mesiac zadarmo"
        description="Začínate podnikať alebo nie ste spokojný so súčasným účtovníkom? Napíšte nám a celý proces zmeny účtovníka vybavíme za Vás."
      />
      <Cookies />
      <ResponsiveContext.Consumer>{(size: string): JSX.Element => <Slovacc size={size} />}</ResponsiveContext.Consumer>
      <form
        style={{ width: `100%` }}
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" />
        <TextInput
          className="bg-grey"
          plain
          name="name"
          placeholder="meno/názov spoločnosti"
          width="100%"
          type="hidden"
          id="name"
        />
        <TextInput id="phone" className="bg-grey" plain placeholder="tel. č." width="100%" name="phone" type="hidden" />
        <TextInput id="email" className="bg-grey" plain placeholder="email" width="100%" name="email" type="hidden" />
        <TextInput
          id="subject"
          className="bg-grey"
          plain
          placeholder="email"
          width="100%"
          name="subject"
          type="hidden"
        />
        <TextArea
          id="message"
          className="bg-grey"
          name="message"
          plain
          rows={8}
          placeholder="správa..."
          style={{ display: `none` }}
        />
      </form>
    </GrommetWrapper>
  )
}
export default IndexPage
